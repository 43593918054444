const MontageService = (isGascade) => {

    const GASCADE = isGascade;

    const REISEKOSTEN = 1.55;
    const UMWEG = 50;
    const ARBEITSZEIT = 111.2;
    const UEBERSTUNDE = 145;
    const UEBERNACHTUNGSPAUSCHALE = 140;
    const AUSLOESUNGINLAND = 115.1;
    const AUSLOESUNGAUSLAND = 151.9;

    const TAGESPAUSCHALE_GASCADE = 1035;
    const HALBTAGESPAUSCHALE_GASCADE = 575;

    function getTagesPauschale(montage) {
        if(GASCADE) {
            return TAGESPAUSCHALE_GASCADE
        }
        let result = 8 * ARBEITSZEIT + ((montage.inland) ? AUSLOESUNGINLAND : AUSLOESUNGAUSLAND);
        return Math.ceil(result);
    }

    function tagesPauschale_summe(montage) {
        return montage.mitarbeiterzahl * montage.tagespauschale * getTagesPauschale(montage)
    }

    function getHalbtagesPauschale(montage) {
        if(GASCADE) {
            return HALBTAGESPAUSCHALE_GASCADE
        }
        let result = 4 * ARBEITSZEIT + ((montage.inland) ? AUSLOESUNGINLAND : AUSLOESUNGAUSLAND);
        return Math.ceil(result);
    }

    function halbtagesPauschale_summe(montage) {
        return montage.mitarbeiterzahl * Number(montage.halbtagspauschale) * getHalbtagesPauschale(montage)
    }

    function ueberstunden_summe(montage) {
        return montage.mitarbeiterzahl * montage.ueberstunden * UEBERSTUNDE;
    }

    function uebernachtung_summe(montage) {
        return montage.mitarbeiterzahl * montage.uebernachtung * UEBERNACHTUNGSPAUSCHALE;
    }

    function getEntfernung(montage) {
        return (((2 * montage.entfernung) + UMWEG) * REISEKOSTEN) + (ARBEITSZEIT * 2);
    }

    function getEntfernung_summe(montage) {
        return montage.fahrzeugezahl * montage.reisekostenpauschale *  getEntfernung(montage);
    }

    function getSum(montage) {
        montage.sumMontage = (montage.mitarbeiterzahl * montage.halbtagspauschale * getHalbtagesPauschale(montage)) + (montage.mitarbeiterzahl * montage.tagespauschale * getTagesPauschale(montage)) + (montage.mitarbeiterzahl * montage.ueberstunden * UEBERSTUNDE) + (montage.mitarbeiterzahl * montage.uebernachtung * UEBERNACHTUNGSPAUSCHALE) + (montage.fahrzeugezahl * montage.reisekostenpauschale * getEntfernung(montage))
        return montage.sumMontage;
    }

    return {
        getHalbtagesPauschale,
        getTagesPauschale,
        getEntfernung,
        getSum,
        tagesPauschale_summe,
        halbtagesPauschale_summe,
        ueberstunden_summe,
        uebernachtung_summe,
        getEntfernung_summe,
        UEBERSTUNDE,
        UEBERNACHTUNGSPAUSCHALE
    };
};

export default MontageService
