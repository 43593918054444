const MaterialService = () => {

    /* 10 Jahreswertung */
    const articles10 = {
        SEHAZ: {
            old: {
                article: 104008000052,
                price: 197.57,
                text: 'Ersatzteilliste aus Wartung SEHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000053,
                price: 196.00,
                text: 'Ersatzteilliste aus Wartung SEHAZeco (neue Ausführung)'
            }
        },
        EHAZ: {
            old: {
                article: 104008000038,
                price: 258.31,
                text: 'Ersatzteilliste aus Wartung EHAZ, HAZ mit Steuerung (alte Ausführung)'
            },
            new: {
                article: 104008000039,
                price: 452.24,
                text: 'Ersatzteilliste aus Wartung EHAZ, HAZ mit Steuerung (neue Ausführung)'
            }
        },
        SEHAZc: {
            old: {
                article: 104008000052,
                price: 197.57,
                text: 'Ersatzteilliste aus Wartung SEHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000053,
                price: 196.00,
                text: 'Ersatzteilliste aus Wartung SEHAZeco (neue Ausführung)'
            }
        },
        GEHAZ: {
            old: {
                article: 104008000048,
                price: 132.11,
                text: 'Ersatzteilliste aus Wartung GEHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000049,
                price: 127.96,
                text: 'Ersatzteilliste aus Wartung GEHAZeco (neue Ausführung)'
            }
        },
        GHAZ: {
            old: {
                article: 104008000046,
                price: 23.39,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000047,
                price: 30.49,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        SAE: {
            old: {
                article: 104008000050,
                price: 667.81,
                text: 'Ersatzteilliste aus Wartung SAE (alte Ausführung)'
            },
            new: {
                article: 104008000051,
                price: 249.74,
                text: 'Ersatzteilliste aus Wartung SAE (neue Ausführung)'
            }
        },
        PAZ: {
            old: {
                article: 104008000056,
                price: 6.02,
                text: 'Ersatzteilliste aus Wartung PAZ, Antriebe'
            },
            new: {
                article: 104008000056,
                price: 6.02,
                text: 'Ersatzteilliste aus Wartung PAZ, Antriebe'
            }
        },
        GAZ: {
            old: {
                article: 104008000046,
                price: 23.39,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000047,
                price: 30.49,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        HAZ: {
            old: {
                article: 104008000038,
                price: 258.31,
                text: 'Ersatzteilliste aus Wartung EHAZ, HAZ mit Steuerung (alte Ausführung)'
            },
            new: {
                article: 104008000039,
                price: 452.24,
                text: 'Ersatzteilliste aus Wartung EHAZ, HAZ mit Steuerung (neue Ausführung)'
            }
        },
        ZEHAZ: {
            old: {
                article: 104008000040,
                price: 418.90,
                text: 'Ersatzteilliste aus Wartung ZEH (alte Ausführung)'
            },
            new: {
                article: 104008000041,
                price: 629.10,
                text: 'Ersatzteilliste aus Wartung ZEH (neue Ausführung)'
            }
        },
        MHAZ: {
            old: {
                article: 104008000042,
                price: 142.14,
                text: 'Ersatzteilliste aus Wartung MHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000043,
                price: 209.32,
                text: 'Ersatzteilliste aus Wartung MHAZ (neue Ausführung)'
            }
        },
        EHAZR: {
            old: {
                article: 104008000045,
                price: 834.65,
                text: 'Ersatzteilliste aus Wartung EHAZ-R (neue Ausführung)'
            },
            new: {
                article: 104008000045,
                price: 834.65,
                text: 'Ersatzteilliste aus Wartung EHAZ-R (neue Ausführung)'
            }
        },
        GHAZ2: {
            old: {
                article: 104008000046,
                price: 23.39,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000047,
                price: 30.49,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        SEHAZR: {
            old: {
                article: 104008000054,
                price: 201.77,
                text: 'Ersatzteilliste aus Wartung SEHAZ-R (alte Ausführung)'
            },
            new: {
                article: 104008000055,
                price: 200.20,
                text: 'Ersatzteilliste aus Wartung SEHAZeco-R (neue Ausführung)'
            }
        },
        DEHAZ: {
            old: {
                article: 104008000058,
                price: 6.02,
                text: 'Ersatzteilliste aus Wartung DEHAZ, Antriebe '
            },
            new: {
                article: 104008000058,
                price: 6.02,
                text: 'Ersatzteilliste aus Wartung DEHAZ, Antriebe '
            }
        },
        SONSTIGES: {
            old: {
                article: 2,
                price: 0,
                text: 'xxx'
            },
            new: {
                article: 2,
                price: 0,
                text: 'xxx'
            }
        }
    }

    /* 5 Jahreswertung */
    const articles5 = {
        SEHAZ: {
            old: {
                article: 104008000034,
                price: 106.93,
                text: 'Ersatzteilliste aus Wartung SEHAZeco (neue Ausführung)'
            },
            new: {
                article: 104008000034,
                price: 106.93,
                text: 'Ersatzteilliste aus Wartung SEHAZeco (neue Ausführung)'
            }
        },
        EHAZ: {
            old: {
                article: 104008000019,
                price: 142.14,
                text: 'Ersatzteilliste aus Wartung EHAZ, HAZ mit Steuerung (alte Ausführung)'
            },
            new: {
                article: 104008000020,
                price: 336.07,
                text: 'Ersatzteilliste aus Wartung EHAZ, HAZ mit Steuerung (neue Ausführung)'
            }
        },
        SEHAZc: {
            old: {
                article: 104008000034,
                price: 106.93,
                text: 'Ersatzteilliste aus Wartung SEHAZeco (neue Ausführung)'
            },
            new: {
                article: 104008000034,
                price: 106.93,
                text: 'Ersatzteilliste aus Wartung SEHAZeco (neue Ausführung)'
            }
        },
        GEHAZ: {
            old: {
                article: 104008000029,
                price: 30.04,
                text: 'Ersatzteilliste aus Wartung GEHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000030,
                price: 29.80,
                text: 'Ersatzteilliste aus Wartung GEHAZeco (neue Ausführung)'
            }
        },
        GHAZ: {
            old: {
                article: 104008000027,
                price: 17.37,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000028,
                price: 24.46,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        SAE: {
            old: {
                article: 104008000031,
                price: 667.81,
                text: 'Ersatzteilliste aus Wartung SAE (alte Ausführung)'
            },
            new: {
                article: 104008000032,
                price: 249.74,
                text: 'Ersatzteilliste aus Wartung SAE (neue Ausführung)'
            }
        },
        PAZ: {
            old: {
                article: 104008000037,
                price: 6.02,
                text: 'Ersatzteilliste aus Wartung PAZ, Antriebe'
            },
            new: {
                article: 104008000037,
                price: 6.02,
                text: 'Ersatzteilliste aus Wartung PAZ, Antriebe'
            }
        },
        GAZ: {
            old: {
                article: 104008000027,
                price: 17.37,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000028,
                price: 24.46,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        HAZ: {
            old: {
                article: 104008000019,
                price: 142.14,
                text: 'Ersatzteilliste aus Wartung EHAZ, HAZ mit Steuerung (alte Ausführung)'
            },
            new: {
                article: 104008000020,
                price: 336.07,
                text: 'Ersatzteilliste aus Wartung EHAZ, HAZ mit Steuerung (neue Ausführung)'
            }
        },
        ZEHAZ: {
            old: {
                article: 104008000021,
                price: 296.97,
                text: 'Ersatzteilliste aus Wartung ZEH (alte Ausführung)'
            },
            new: {
                article: 104008000022,
                price: 506.90,
                text: 'Ersatzteilliste aus Wartung ZEH (neue Ausführung)'
            }
        },
        MHAZ: {
            old: {
                article: 104008000023,
                price: 142.14,
                text: 'Ersatzteilliste aus Wartung MHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000024,
                price: 209.32,
                text: 'Ersatzteilliste aus Wartung MHAZ (neue Ausführung)'
            }
        },
        EHAZR: {
            old: {
                article: 104008000025,
                price: 142.14,
                text: 'Ersatzteilliste aus Wartung EHAZ-R (alte Ausführung)'
            },
            new: {
                article: 104008000026,
                price: 336.07,
                text: 'Ersatzteilliste aus Wartung EHAZ-R (neue Ausführung)'
            }
        },
        GHAZ2: {
            old: {
                article: 104008000027,
                price: 17.37,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000028,
                price: 24.46,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        SEHAZR: {
            old: {
                article: 104008000035,
                price: 94.40,
                text: 'Ersatzteilliste aus Wartung SEHAZ-R (alte Ausführung)'
            },
            new: {
                article: 104008000036,
                price: 106.88,
                text: 'Ersatzteilliste aus Wartung SEHAZeco-R (neue Ausführung)'
            }
        },
        DEHAZ: {
            old: {
                article: 104008000057,
                price: 6.02,
                text: 'Ersatzteilliste aus Wartung DEHAZ, Antriebe'
            },
            new: {
                article: 104008000057,
                price: 6.02,
                text: 'Ersatzteilliste aus Wartung DEHAZ, Antriebe'
            }
        },
        SONSTIGES: {
            old: {
                article: 2,
                price: 0,
                text: 'xxx'
            },
            new: {
                article: 2,
                price: 0,
                text: 'xxx'
            }
        }
    }

    /* TODO:  Sehaz Compact identisch mit SEHAZ? */
    /* 1 Jahreswertung */
    const articles1 = {
        SEHAZ: {
            old: {
                article: 104008000015,
                price: 7.39,
                text: 'Ersatzteilliste aus Wartung SEHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000016,
                price: 18.82,
                text: 'Ersatzteilliste aus Wartung SEHAZeco (neue Ausführung)'
            }
        },
        EHAZ: {
            old: {
                article: 104008000001,
                price: 47.25,
                text: 'Ersatzteilliste aus Wartung EHAZ u. HAZ mit Steuerung (alte Ausführung)'
            },
            new: {
                article: 104008000002,
                price: 32.13,
                text: 'Ersatzteilliste aus Wartung EHAZ HAZ mit Steuerung (neue Ausführung)'
            }
        },
        SEHAZc: {
            old: {
                article: 104008000015,
                price: 7.39,
                text: 'Ersatzteilliste aus Wartung SEHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000016,
                price: 18.82,
                text: 'Ersatzteilliste aus Wartung SEHAZeco (neue Ausführung)'
            }
        },
        GEHAZ: {
            old: {
                article: 104008000011,
                price: 0.62,
                text: 'Ersatzteilliste aus Wartung GEHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000012,
                price: 0.62,
                text: 'Ersatzteilliste aus Wartung GEHAZeco (neue Ausführung)'
            }
        },
        GHAZ: {
            old: {
                article: 104008000009,
                price: 10.81,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000010,
                price: 17.90,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        SAE: {
            old: {
                article: 104008000013,
                price: 112.34,
                text: 'Ersatzteilliste aus Wartung SAE (alte Ausführung)'
            },
            new: {
                article: 104008000014,
                price: 89.55,
                text: 'Ersatzteilliste aus Wartung SAE (neue Ausführung)'
            }
        },
        PAZ: {
            old: {
                article: 0,
                price: 0,
                text: 'xxx'
            },
            new: {
                article: 0,
                price: 0,
                text: 'xxx'
            }
        },
        GAZ: {
            old: {
                article: 104008000009,
                price: 10.81,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000010,
                price: 17.90,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        HAZ: {
            old: {
                article: 104008000001,
                price: 47.25,
                text: 'Ersatzteilliste aus Wartung EHAZ u. HAZ mit Steuerung (alte Ausführung)'
            },
            new: {
                article: 104008000002,
                price: 32.13,
                text: 'Ersatzteilliste aus Wartung EHAZ HAZ mit Steuerung (neue Ausführung)'
            }
        },
        ZEHAZ: {
            old: {
                article: 104008000003,
                price: 47.25,
                text: 'Ersatzteilliste aus Wartung ZEH (alte Ausführung)'
            },
            new: {
                article: 104008000004,
                price: 32.13,
                text: 'Ersatzteilliste aus Wartung ZEH (neue Ausführung)'
            }
        },
        MHAZ: {
            old: {
                article: 104008000005,
                price: 47.25,
                text: 'Ersatzteilliste aus Wartung MHAZ (alte Ausführung)'
            },
            new: {
                article: 104008000006,
                price: 32.13,
                text: 'Ersatzteilliste aus Wartung MHAZ (neue Ausführung)'
            }
        },
        EHAZR: {
            old: {
                article: 104008000007,
                price: 47.25,
                text: 'Ersatzteilliste aus Wartung EHAZ-R (alte Ausführung)'
            },
            new: {
                article: 104008000008,
                price: 32.13,
                text: 'Ersatzteilliste aus Wartung EHAZ-R (neue Ausführung)'
            }
        },
        GHAZ2: {
            old: {
                article: 104008000009,
                price: 10.81,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (alte Ausführung)'
            },
            new: {
                article: 104008000010,
                price: 17.90,
                text: 'Ersatzteilliste aus Wartung GAZ, GHAZ und GHAZ2 (neue Ausführung)'
            }
        },
        SEHAZR: {
            old: {
                article: 104008000017,
                price: 7.39,
                text: 'Ersatzteilliste aus Wartung SEHAZ- R (alte Ausführung)'
            },
            new: {
                article: 104008000018,
                price: 18.82,
                text: 'Ersatzteilliste aus Wartung SEHAZeco-R (neue Ausführung)'
            }
        },
        DEHAZ: {
            old: {
                article: 1,
                price: 0,
                text: 'xxx'
            },
            new: {
                article: 1,
                price: 0,
                text: 'xxx'
            }
        },
        SONSTIGES: {
            old: {
                article: 2,
                price: 0,
                text: 'xxx'
            },
            new: {
                article: 2,
                price: 0,
                text: 'xxx'
            }
        }
    }

    const wartungsPreise = {
            SAE: [680, 680, 680, 680, 2610, 680, 680, 680, 680, 2610],
            GAZ: [125, 125, 125, 125, 230, 125, 125, 125, 125, 260],
            GEHAZ: [30, 30, 30, 30, 280, 30, 30, 30, 30, 830],
            EHAZ: [415, 415, 415, 415, 1720, 415, 415, 415, 415, 2360],
            EHAZR: [415, 415, 415, 415, 1820, 415, 415, 415, 415, 4030],
            SEHAZ: [230, 230, 230, 230, 710, 230, 230, 230, 230, 1100],
            SEHAZc: [215, 215, 215, 215, 620, 215, 215, 215, 215, 850],
            DEHAZ: [0, 0, 0, 0, 60, 0, 0, 0, 0, 60],
            PAZ: [0, 0, 0, 0, 60, 0, 0, 0, 0, 60],
            HAZ: [415, 415, 415, 415, 1720, 415, 415, 415, 415, 2360],
        ANTRIEB: [0, 0, 0, 0, 60, 0, 0, 0, 0, 60],
            SEHAZR: [230, 230, 230, 230, 710, 230, 230, 230, 230, 1180],
            MHAZ: [330, 330, 330, 330, 1150, 330, 330, 330, 330, 1150],
            ZEH: [415, 415, 415, 415, 2580, 415, 415, 415, 415, 3180],
            GHAZ: [125, 125, 125, 125, 230, 125, 125, 125, 125, 260],
            SONSTIGES: [0, 0, 0, 0, 60, 0, 0, 0, 0, 60]
    }

    const wartungsArbeitszeit = {
        SAE: [2, 2, 2, 2, 4, 2, 2, 2, 2, 5],
        GAZ: [2, 2, 2, 2, 4, 2, 2, 2, 2, 5],
        GEHAZ: [1, 1, 1, 1, 2, 1, 1, 1, 1, 4],
        EHAZ: [3, 3, 3, 3, 5, 3, 3, 3, 3, 6],
        EHAZR: [3, 3, 3, 3, 5, 3, 3, 3, 3, 6],
        SEHAZ: [3, 3, 3, 3, 4, 3, 3, 3, 3, 5],
        DEHAZ: [0.5, 0.5, 0.5, 0.5, 1, 0.5, 0.5, 0.5, 0.5, 1],
        PAZ: [0.5, 0.5, 0.5, 0.5, 1, 0.5, 0.5, 0.5, 0.5, 1],
        HAZ: [3, 3, 3, 3, 5, 3, 3, 3, 3, 6],
        ANTRIEB: [0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5],
        SEHAZR: [3, 3, 3, 3, 4, 3, 3, 3, 3, 5],
        MHAZ: [3, 3, 3, 3, 5, 3, 3, 3, 3, 6],
        ZEH: [4, 4, 4, 4, 5, 4, 4, 4, 4, 6],
        GHAZ: [2, 2, 2, 2, 4, 2, 2, 2, 2, 5],
        SONSTIGES: [0.5, 0.5, 0.5, 0.5, 1, 0.5, 0.5, 0.5, 0.5, 1]
    }


    const getArticleText = function (machine) {
        if(machine.inspectionYears === 10) {
            if(machine.actuatorOption === "OLD") {
                return articles10[machine.actuator].old.text;
            }
            return articles10[machine.actuator].new.text;
        } else if(machine.inspectionYears === 5) {
            if(machine.actuatorOption === "OLD") {
                return articles5[machine.actuator].old.text;
            }
            return articles5[machine.actuator].new.text;
        } else if(machine.inspectionYears) {
            if(machine.actuatorOption === "OLD") {
                return articles1[machine.actuator].old.text;
            }
            return articles1[machine.actuator].new.text;
        } else {
            return machine.kurztext
        }
    }

    const getArticleTextGascade = function (machine) {
        if(machine.actuatorOption === "OLD") {
            return articles1[machine.actuator].old.text;
        }
        return articles1[machine.actuator].new.text;
    }


    const getArticleNo = function (machine) {
        if(machine.inspectionYears === 10) {
            if(machine.actuatorOption === "OLD") {
                return articles10[machine.actuator].old.article;
            }
            return articles10[machine.actuator].new.article;
        } else if(machine.inspectionYears === 5) {
            if(machine.actuatorOption === "OLD") {
                return articles5[machine.actuator].old.article;
            }
            return articles5[machine.actuator].new.article;
        } else if(machine.inspectionYears) {
            if(machine.actuatorOption === "OLD") {
                return articles1[machine.actuator].old.article;
            }
            return articles1[machine.actuator].new.article;
        } else {
            return machine.artikelNr
        }
    }

    const getArticleNoGascade = function (machine) {
        if(machine.actuatorOption === "OLD") {
            return articles1[machine.actuator].old.article;
        }
        return articles1[machine.actuator].new.article;
    }

    const getWartungspreis = function(item) {
        if(item.inspectionYears && item.inspectionYears !== 0) {
            if(item.inspectionYears > 10) {
                item.inspectionYears -= 10;
            }
            return wartungsPreise[item.actuator][item.inspectionYears-1];
        } else {
            return +item.preisStk;
        }
    }

    const getWartungspreisGascade = function(item) {
        if(item.inspectionYears && item.inspectionYears !== 0) {
            return wartungsPreise[item.actuator][0];
        } else {
            return +item.preisStk;
        }
    }

    const getPriceWithFactor = function(item) {
        return item.preisStk * rangeBoughtItems(item);
    }

    /**
     * 30....
     * @param item
     * @returns {number}
     */
    const rangeBoughtItems = function (item) {
        switch (true) {
            case item.preisStk < 0.10:
                return 53.1;
            case item.preisStk < 0.50:
                return  33.4;
            case item.preisStk < 1:
                return  19.8;
            case item.preisStk < 5:
                return  9.3;
            case item.preisStk < 10:
                return  6.9;
            case item.preisStk < 50:
                return 5.5;
            case item.preisStk < 100:
                return 4;
            case item.preisStk < 300:
                return 3.1;
            case item.preisStk < 700:
                return 2.9;
            case item.preisStk < 1500:
                return 2.6;
            case item.preisStk < 3000:
                return 2.5;
            case item.preisStk < 5000:
                return 2.4
            default:
                return 2.3
        }
    }

    /**
     * 10.....
     * @param item
     * @returns {number}
     */
    const rangeProducedItems = function (item){
        switch (true) {
        case item.preisStk < 0.10:
            return 66.8;
        case item.preisStk < 0.50:
            return 40.8;
        case item.preisStk < 1:
            return 27.3;
        case item.preisStk < 5:
            return 10.6;
        case item.preisStk < 10:
            return 8.1;
        case item.preisStk < 50:
            return 6.9;
        case item.preisStk < 100:
            return 5.1;
        case item.preisStk < 300:
            return 4.3;
        case item.preisStk < 700:
            return 3.7;
        case item.preisStk < 1500:
            return 3.1;
        case item.preisStk < 3000:
            return 2.9;
        case item.preisStk < 5000:
            return 2.6
        default:
            return 2.5
        }
    }

    return {
        articles10,
        articles5,
        articles1,
        wartungsPreise,
        wartungsArbeitszeit,
        getArticleText,
        getArticleTextGascade,
        getArticleNo,
        getArticleNoGascade,
        getWartungspreis,
        getWartungspreisGascade,
        getPriceWithFactor
    };
};

export default MaterialService
